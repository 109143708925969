<template>
    <div>
        <!--  TOP PART (gallery + panel)  -->
        <BaseContainerContent class="mon-product__hero">
            <!--  Brand, Name, Description, Tags  -->
            <div class="mon-product__header">
                <!--  TODO: add real brand  -->
                <div class="mon-text-extra-small mon-font-light mb-2 uppercase md:mb-6">
                    milk_shake
                </div>

                <h1 class="mon-h2 mb-2 md:mb-9">
                    {{ product.name }}
                </h1>

                <p class="mon-text-base mon-font-light mb-4">
                    {{ product.shortDescription }}

                    <!--  TODO: add real link to full description  -->
                    <NuxtLink :to="{ hash: `#` }" class="mon-link whitespace-nowrap">
                        {{ $t('labels.full_description') }}
                    </NuxtLink>
                </p>

                <div class="flex flex-wrap gap-2">
                    <UiBadgeAutoColor
                        v-for="tag in product.tags"
                        :key="tag.id"
                        :label="tag.label"
                    />
                </div>
            </div>

            <!--  Gallery  -->
            <div class="mon-product__gallery">
                <ProductGallery
                    :product-images="productImages"
                    :product-videos="productVideos"
                />
            </div>


            <div v-if="!isB2bOnly" class="mon-product__actions flex flex-col gap-8 max-md:mt-2">
                <slot name="actions" />

                <!--  TODO: only show for products with free shipping set  -->
                <CardIconCircle
                    :title="$t('product.product_free_shipping')"
                    :description="$t('product.product_free_shipping_info')"
                    icon-alignment="start"
                >
                    <IconShipping :size="20" />
                </CardIconCircle>
            </div>

            <!--  This product is available only for B2B customers  -->
            <div v-else class="mon-border-top mon-border-bottom flex flex-col items-center gap-4 text-balance py-12">
                <!--  TODO: change to #D80027 (new danger color), after color name refactor  -->
                <UiIconContainer size="xl" color="danger" aria-hidden="true">
                    <span class="mon-custom-font mon-product__exclamation">
                        !
                    </span>
                </UiIconContainer>

                <span class="mon-subtitle text-center">
                    {{ $t('product.product_only_for_b2b') }}
                </span>

                <i18n-t
                    tag="p"
                    keypath="product.to_purchase_you_must_be_b2b"
                    scope="global"
                    class="mon-text-base text-center"
                >
                    <template #b2bcustomer>
                        <!--  TODO: link to some B2B page?  -->
                        <NuxtLink to="#" class="mon-link">
                            {{ $t('product.to_purchase__b2b_customer') }}
                        </NuxtLink>
                    </template>

                    <template #salons>
                        <!--  TODO: update with link when partner salons are implemented  -->
                        <span>
                            {{ $t('product.to_purchase__salons') }}
                        </span>
                    </template>
                </i18n-t>

                <BaseUiButton class="mt-4" variant="outlined" :to="getThemePath('login')">
                    {{ $t('labels.log_in_to_b2b') }}
                </BaseUiButton>
            </div>

        </BaseContainerContent>

    </div>
</template>

<script lang="ts" setup>

const {
    product,
    productImages,
    productVideos,
    isB2bOnly,
} = defineProps<{
    product: InstanceType<typeof ProductModel>
    productImages: InstanceType<typeof ProductImageModel>[]
    productVideos: InstanceType<typeof ProductVideoModel>[]
    isB2bOnly?: boolean
}>()

const { getThemePath } = useThemePaths()

</script>

<style lang="scss" scoped>

.mon-product__hero {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include more-than(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        grid-template-areas:
                "gallery header"
                "gallery actions";
    }
}

$side-panel-desktop-width: 37.5rem;

.mon-product__header {
    grid-area: header;

    @include more-than-custom(1440) {
        max-width: $side-panel-desktop-width;
        margin: 0 auto;
        width: 100%;
    }
}

.mon-product__actions {
    grid-area: actions;

    @include more-than-custom(1440) {
        max-width: $side-panel-desktop-width;
        margin: 0 auto;
    }
}

.mon-product__gallery {
    grid-area: gallery;

    $max-mobile-width: 32rem;

    // ensure that the mobile gallery is not too high
    @include less-than(md) {
        max-width: $max-mobile-width;
        margin: 0 auto;

        height: 0;
        padding-bottom: min(100%, $max-mobile-width);
        width: 100%;
    }
}

.mon-product__exclamation {
    font-size: 1.5rem;

    @include more-than(lg) {
        font-size: 2rem;
    }
}

</style>
