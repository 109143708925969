<template>
    <a
        class="inline-flex"
        :data-pswp-width="props.srcWidth ?? 0"
        :data-pswp-height="props.srcHeight ?? 0"
        :data-cropped="true"
        :data-pswp-type="videoEmbedUrl ? 'video' : undefined"
        :href="videoEmbedUrl ?? props.src ?? ''"
        target="_blank"
        rel="noreferrer"
    >
        <CoreImg
            v-bind="coreImgProps"
            :class="imgClass"
        />
    </a>
</template>

<script lang="ts" setup>
import type { CoreImgProps } from './CoreImg.vue'
import type { HTMLAttributes } from 'vue'

const props = defineProps<Omit<CoreImgProps<any>, 'width' | 'height' | 'sizes'> & {
    srcWidth: number | null
    srcHeight: number | null
    thumbnail?: {
        width?: number
        height?: number
        sizes?: string
    }
    video?: InstanceType<typeof ProductVideoModel>
    imgClass?: HTMLAttributes['class']
}>()

const coreImgProps = computed<CoreImgProps<any>>(() => {
    const { srcWidth, srcHeight, thumbnail, src, ...rest } = props
    return {
        ...rest,
        src: props.src ?? '',
        width: props.thumbnail?.width,
        height: props.thumbnail?.height,
        sizes: props.thumbnail?.sizes,
    }
})

const videoEmbedUrl = computed<string | undefined>(() => {
    if (!props.video) return
    return props.video.getIframeUrl() ?? undefined
})

</script>

<style lang="scss">

// class added dynamically by javascript to the photoswipe slide content
.pswp__video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    padding: 2rem;

    @include more-than(md) {
        padding: 5rem;
    }
}

.pswp__video-container iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    max-width: min(100%, 1920px);
    max-height: min(100%, 1080px);
    pointer-events: auto;
}

</style>
