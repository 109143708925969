<template>
    <CorePhotoswipeProvide ref="gallery" tag="div" class="mon-gallery">
        <!--  THUMBS  -->
        <ProductGalleryItem
            v-for="(thumb, index) in thumbnails.items"
            :key="index"
            class="max-md:hidden"
            :media="thumb"
            type="thumb"
            tag="button"
            @click="lightbox.openIndex(index)"
        />

        <ProductGalleryItem
            v-if="thumbnails.shouldTruncate"
            class="max-md:hidden"
            type="thumb"
            tag="button"
            @click="lightbox.openIndex(THUMBNAIL_COUNT - 1)"
        >
            {{ `+${thumbnails.remaining}` }}
        </ProductGalleryItem>


        <!--  SLIDESHOW  -->
        <CoreSwiperWrapper>
            <UiCard class="mon-gallery__slideshow" size="none">
                <CoreSwiper
                    :slides="productMedia"
                    :slide-gap="1"
                    type="gallery-main"
                    class="h-full"
                >
                    <template #slide="{ slide }">
                        <ProductGalleryItem :media="slide" />
                    </template>
                </CoreSwiper>

                <!--  NAVIGATION ARROWS  -->
                <CoreSwiperNavigation v-slot="{ slidePrev, slideNext, areMultipleSlides, isLastSlide, isFirstSlide }">
                    <TransitionGroup>
                        <UiButtonArrow
                            v-show="areMultipleSlides && !isFirstSlide"
                            key="slide-prev"
                            class="mon-gallery__controls mon-gallery__controls--prev md:hidden"
                            direction="left"
                            @click="slidePrev"
                        />

                        <UiButtonArrow
                            v-show="areMultipleSlides && !isLastSlide"
                            key="slide-next"
                            class="mon-gallery__controls mon-gallery__controls--next md:hidden"
                            direction="right"
                            @click="slideNext"
                        />
                    </TransitionGroup>
                </CoreSwiperNavigation>
            </UiCard>
        </CoreSwiperWrapper>
    </CorePhotoswipeProvide>
</template>

<script lang="ts" setup>

const {
    productImages,
    productVideos,
    selectedVariation,
} = defineProps<{
    productImages: InstanceType<typeof ProductImageModel>[]
    productVideos: InstanceType<typeof ProductVideoModel>[]
    selectedVariation?: InstanceType<typeof ProductVariationModel> | null
}>()

const THUMBNAIL_COUNT = 5

const productMedia = computed(() => {
    const hasVariationImages = productImages.some(image => image.productVariationId === selectedVariation?.id)
    const images = productImages.filter(image => selectedVariation
        // show only variation images if variation is selected or, if the variation doesn't have images, show only default product images
        ? hasVariationImages ? image.productVariationId === selectedVariation.id : image.productVariationId === null
        // show default product images if no variation is selected
        : image.productVariationId === null
    )

    const defaultImages = images.filter(image => image.isDefault)
    const nonDefaultImages = images.filter(image => !image.isDefault)

    return [...defaultImages, ...nonDefaultImages, ...productVideos]
})

const thumbnails = computed(() => {
    const shouldTruncate = productMedia.value.length > THUMBNAIL_COUNT

    return {
        remaining: shouldTruncate ? productMedia.value.length - THUMBNAIL_COUNT : 0,
        items: productMedia.value.slice(0, shouldTruncate ? THUMBNAIL_COUNT - 1 : THUMBNAIL_COUNT),
        shouldTruncate: shouldTruncate,
    }
})

const galleryEl = useTemplateRef<ComponentPublicInstance>('gallery')

const lightbox = usePhotoswipe({
    gallery: galleryEl,
    transition: 'fade',
})

</script>

<style lang="scss" scoped>

$thumbnail-count: 5;

.mon-gallery {
    display: grid;
    grid-template-columns: repeat($thumbnail-count + 1, 1fr);
    grid-template-rows: repeat($thumbnail-count, 1fr);
    gap: 1rem;

    @include less-than(md) {
        aspect-ratio: 1;
    }
}

.mon-gallery__slideshow {
    position: relative;

    grid-column: span $thumbnail-count + 1;
    grid-row: span $thumbnail-count;


    @include more-than(md) {
        grid-column: 2 / span $thumbnail-count;
        grid-row: 1 / span $thumbnail-count;
    }
}

.mon-gallery__controls {
    position: absolute !important;
    top: 50%;

    &.v-enter-active,
    &.v-leave-active {
        transition: opacity $mon-trans-time-long $mon-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
    }
}

.mon-gallery__controls--prev {
    left: 0;
    transform: translate(25%, -50%);

    @include more-than-custom(620) {
        transform: translate(-50%, -50%);
    }
}

.mon-gallery__controls--next {
    right: 0;
    transform: translate(-25%, -50%);

    @include more-than-custom(620) {
        transform: translate(50%, -50%);
    }
}

</style>
