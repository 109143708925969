<template>
    <LayoutProductDetail
        v-if="product"
        :product="product"
        :product-images="productImages"
        :product-videos="productVideos"
    >
        <template #actions>
            <div class="flex items-center justify-between gap-8">
                <!-- TODO: hide availability for packages & products with variants  -->
                <ProductStockInfo
                    :product="product"
                >
                    300 ml
                </ProductStockInfo>

                <!--  TODO: show only for certain products - ask BE  -->
                <i18n-t
                    scope="global"
                    keypath="product.cheaper_for_professionals"
                    tag="p"
                    class="mon-text-extra-small max-xl:hidden"
                >
                    <template #login>
                        <!--  TODO: update path?  -->
                        <NuxtLink :to="getThemePath('login')" class="mon-link">
                            {{ $t('labels.to_log_in').toLocaleLowerCase() }}
                        </NuxtLink>
                    </template>

                    <template #register>
                        <!--  TODO: update path?  -->
                        <NuxtLink :to="getThemePath('login')" class="mon-link">
                            {{ $t('labels.to_register').toLocaleLowerCase() }}
                        </NuxtLink>
                    </template>
                </i18n-t>

                <ProductPrice
                    type="block"
                    :product="product"
                />
            </div>

            <!--  TODO: only show for products with coupon discount (waiting for BE)  -->
            <ProductCouponCard :product="product" />

            <div class="flex justify-between gap-x-2 gap-y-4 max-[360px]:flex-wrap sm:gap-4">
                <!--  TODO: replace with BaseUiCounter  -->
                <UiCounter
                    v-model="amount"
                    class="w-full max-w-36 max-[360px]:max-w-full lg:shrink-0"
                    counter-class="bg-primary-100"
                    :max-value="product.getMaxPurchasableAmount()"
                    :disabled="product.isAddToCartButtonDisabled()"
                />

                <ProductAddToCartButton
                    type="text"
                    class="w-full"
                    :disabled="product.isAddToCartButtonDisabled()"
                />

                <!--  TODO: maybe extract into a separate component?  -->
                <UiButtonIcon class="shrink-0 max-[360px]:mx-auto" color="primary-light">
                    <IconHeart />
                </UiButtonIcon>
            </div>
        </template>
    </LayoutProductDetail>
    <div v-else>
        <!--  TODO: add error handling  -->
    </div>
</template>

<script lang="ts" setup>

const { page } = useCurrentPage()
const { getThemePath } = useThemePaths()

const amount = ref<number>(1)

const [
    { item: product },
    { items: productImages },
    { items: productVideos },
] = [
    useProductsApiService()
        .embed([
            // ProductModel.EMBED_PROPERTIES,
            ProductModel.EMBED_DISCOUNTED_TAXED_PRICE,
            ProductModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductModel.EMBED_DISCOUNT_PERCENTS,
            // ProductModel.EMBED_ATTACHMENTS,
            ProductModel.EMBED_PRODUCT_AVAILABILITY,
            // ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
            // ProductModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST_ITEM_ID,
            // ProductModel.EMBED_CUSTOMER_PRODUCT_VARIATIONS_WISH_LIST_ITEMS,
            // ProductModel.EMBED_RATING,
            // ProductModel.EMBED_PRODUCT_REVIEW_PARAMETERS,
            // ProductModel.EMBED_PRODUCT_REVIEW_PARAMETER_RATINGS,
            ProductModel.EMBED_TAGS,
        ])
        .forId(page.value?.model?.id)
        .useGet(),   // TODO: add error handling for the case when the model id is not present
    useProductImagesApiService()
        .whereEquals(ProductImageModel.ATTR_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .sortByAsc(ProductImageModel.ATTR_POSITION)
        .embed([
            ProductImageModel.EMBED_IMAGE_URL,
        ])
        .useGet(),
    useProductVideosApiService()
        .whereEquals(ProductVideoModel.ATTR_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .sortByAsc(ProductVideoModel.ATTR_POSITION)
        .useGet(),
]

</script>

<style lang="scss" scoped>

</style>
