<template>
    <UiCard color="danger" class="mon-text-base flex items-center justify-between gap-2">
        <div>
            <i18n-t
                scope="global"
                keypath="product.discounted_price_with_code"
            >
                <template #price>
                    <span class="mon-h4 text-danger-500">
                        <!--  TODO: replace with coupon code price  -->
                        {{ product.getFormattedDiscountedPrice() }}
                    </span>
                </template>

                <template #code>
                    <!--  TODO: add hover tooltip  -->
                    <button type="button" class="mon-h6 inline-flex gap-1" @click="copyCode">
                        {{ code }}

                        <IconDuplicate />
                    </button>
                </template>
            </i18n-t>

            <div class="mon-text-extra-small mon-font-light">
                {{ $t('product.enter_discount_code_in_cart').toLocaleLowerCase() }}
            </div>
        </div>

        <BaseUiBadge
            content-class="mon-font-bold"
            color="danger"
            size="sm"
        >
            <!--  TODO: replace with coupon discount percentage  -->
            {{ `- ${product?.getDiscountPercents()} %` }}
        </BaseUiBadge>
    </UiCard>
</template>

<script lang="ts" setup>

const {
    product,
} = defineProps<{
    product: InstanceType<typeof ProductModel>
}>()

const { t } = useI18n()
const { notifySuccess } = useNotifications()

// TODO: get actual coupon code
const code = computed(() => '15monroo')

async function copyCode() {
    try {
        await navigator.clipboard.writeText(code.value)
        notifySuccess(t('product.coupon_code_copied'))
    } catch (e) {
        console.error(e)
    }
}

</script>

<style lang="scss" scoped>

</style>
